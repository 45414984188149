//  -yzm

//other
let __system = {
  __activeid: "",
  __menu: [
  {
    pageFlag: false,
    id: "syc",
    title: "数据同步",
    icon: "el-icon-menu",
    children: [
    {
      pageFlag: false,
      id: "sycbase",
      title: "基础设置",
      icon: "el-icon-menu",
      children: [
      {
        pageFlag: true,
        id: "account",
        title: "数据库设置",
        name: "account",
        icon: "el-icon-document",
        component: "views/syc/Account.vue",
        visible:true

      },
      {
        pageFlag: true,
        id: "type",
        title: "同步类型设置",
        name: "type",
        icon: "el-icon-document",
        component: "views/syc/Type.vue"
        ,visible:true
      },
      {
        pageFlag: true,
        id: "detail",
        title: "同步明细设置",
        name: "detail",
        icon: "el-icon-document",
        component: "views/syc/Detail.vue"
        ,visible:true
      }]
    },
    {
        pageFlag: false,
        id: "syccommon",
        title: "日常业务",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "partnerclass",
          title: "往来单位分类同步",
          name: "partnerclass",
          icon: "el-icon-document",
          component: "views/syc/PartnerClass.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "partner",
          title: "往来单位同步",
          name: "partner",
          icon: "el-icon-document",
          component: "views/syc/Partner1.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "inventoryclass",
          title: "存货分类同步",
          name: "inventoryclass",
          icon: "el-icon-document",
          component: "views/syc/InventoryClass.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "inventory",
          title: "存货同步",
          name: "inventory",
          icon: "el-icon-document",
          component: "views/syc/Inventory.vue"
          ,visible:true
        },{
          pageFlag: true,
          id: "bom",
          title: "bom同步",
          name: "bom",
          icon: "el-icon-document",
          component: "views/syc/Bom.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "puorderexec",
          title: "采购订单下单",
          name: "puorderexec",
          icon: "el-icon-document",
          component: "views/syc/Puorderexec.vue"
          ,visible:true
        },
        {
          pageFlag: true,
          id: "saletoorder",
          title: "销售订单生成采购订单",
          name: "saletoorder",
          icon: "el-icon-document",
          component: "views/syc/saletoorder.vue"
          ,visible:true
        }
        // {
        //   pageFlag: true,
        //   id: "ax_PU_PurchaseOrder",
        //   title: "多账套采购订单新增",
        //   name: "ax_PU_PurchaseOrder",
        //   icon: "el-icon-document",
        //   component: "views/syc/AxPUPurchaseOrderList.vue",
        //   componentdetail: "views/syc/AxPUPurchaseOrderBDetail.vue"
        //   ,visible:true
        // }
      ],
      
      },
      {
        pageFlag: false,
        id: "sysrpt",
        title: "报表",
        icon: "el-icon-menu",
        children: [
        {
          pageFlag: true,
          id: "rpt1",
          title: "采购订单执行情况表",
          name: "rpt1",
          icon: "el-icon-document",
          component: "views/rpt/Rpt1.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt2",
          title: "库存数据表",
          name: "rpt2",
          icon: "el-icon-document",
          component: "views/rpt/Rpt2.vue",
          visible:true
        },
        {
          pageFlag: true,
          id: "rpt3",
          title: "采购订单执行情况表(剩余金额)",
          name: "rpt3",
          icon: "el-icon-document",
          component: "views/rpt/Rpt3.vue",
          visible:true
        }
      ]}
],
  }, ],
  __mdilist: [],
  set activeid(val) {
    this.__activeid = val;
  },
  get activeid() {
    return this.__activeid;
  },
  set menu(val) {
    this.__menu = val;
  },
  get menu() {
    return this.__menu;
  },
  set mdilist(val) {
    this.__mdilist = val;
  },
  get mdilist() {
    return this.__mdilist;
  }
}


let ld = (function (__system) {
  let _ld = {
    "system": __system,
  }
  return _ld;
})(__system)
export default ld